div.experience-container {
    background-color: rgb(94, 161, 236);
    border-radius: 10px;
    width: 800px;
    padding: 10px 20px 20px 20px;
}

.experience-container h2 {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 0;
}

.experience-container h3 {
    font-size: 24px;
    margin-top: 0;
    display: flex;
    justify-content: left;
}

.experience-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.experience-title {
    flex-basis: 85%;
}

.experience-date {
    flex-basis: 15%;
    display: flex;
    justify-content: right;
}

#Marketo-forms {
    list-style-type: none;
    padding-left: 28px;
}

@media (max-width: 500px) {
    div.experience-container {
        width: 450px;
    }
}
