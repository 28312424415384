.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 300px;
    right: -300px;
    transition: all 0.2s linear;
    background-color: rgb(182, 141, 67);
    box-shadow: 0px 0 8px 1px lightgray;
}

.sidebar-header h2 {
    margin-left: 20px;
}

.cart {
    margin-top: 16px;
    padding: 0 8px;
}

.cart-item-header {
    flex-basis: 60%;
}

.cart-item-button {
    min-width: 24px;
    height: 24px;
    background-color: white;
    margin-left: 8px;
    border-radius: 10px;
}

.cart-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
}

.cart-item input {
    width: 32px;
    height: 24px;
}

.cart button[type="submit"] {
    align-self: flex-end;
    font-size: 18px;
    padding: 0 16px;
}

.cart form {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
}
