html {
    /* #1e4469
    #637383*/
    /* background-color: #1e4469; */
    /* background-image: linear-gradient(to bottom, gray, rgb(201, 204, 44), gray); */
    /* background-image: linear-gradient(to bottom, rgb(121, 178, 238), rgb(232, 232, 125)); */
    background-color: #1d2a35;
    font-family: Arial;
}

#root {
    display: flex;
    flex-flow: column;
    align-items: center;
}

a:link {
    text-decoration: none;
    color: white;
}

a:visited {
    color: unset;
}

#social-media {
    list-style: none;
    margin: 3px;
    padding: 0;
    display: flex;
    column-gap: 10px;
    font-size: 30px;
}

h1 {
    margin-top: 20px;
    font-size: 50px;
    margin-bottom: 20px;
}

#welcome-section{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 400px 400px;
    justify-items: center;
    align-items: center;
    column-gap: 50px;
    color: white;
}

#right-intro {
    font-size: 24px;
    display: flex;
    flex-flow: column;
    align-items: right;
}

#right-intro p {
    margin-bottom: 0;
}

section {
    padding-bottom: 25px;
}

.section-title {
    text-align: center;
    font-size: 56px;
}

h2.section-title {
    margin-top: 15px;
    margin-bottom: 15px;
    background: linear-gradient(
        to right, #f32170, #ff6b08,
        #cf23cf, #eedd44);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

h3.skill-title {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    background-color: aquamarine;
    border-radius: 10px;
    padding: 4px;
}

.projects {
    display: grid;
    justify-content: center;
    justify-items: center;
    gap: 25px 50px;
    grid-template-columns: 400px 400px;
}

.skills {
    display: grid;
    justify-content: center;
    gap: 10px 20px;
    grid-template-columns: 200px 200px 200px;
}

.white-break {
    background-image: linear-gradient(to right, red, yellowgreen);
    height: 10px;
    width: 500px;
    border-radius: 10px;
    z-index: -1;
    display: inline-flex;
    justify-content: center;
    margin-left: 175px;
}

@media (max-width: 844px) {
    #welcome-section {
        column-gap: 0;
    }

    .projects {
        grid-template-columns: 400px;
    }
}

@media (max-width: 670px) {
    .skills {
        grid-template-columns: 200px 200px;
    }
}

@media (max-width: 500px) {
    /* html {
        width: 50%;
        position: absolute; top: 5%; left: 50%;
        transform: (translate(-50%, -50%));
    } */

    #welcome-section{
        padding-top: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    #left-intro {
        align-items: center;
    }
    #left-intro > p {
        display: flex;
        justify-content: center;
    }

    #right-intro {
        width: 400px;
        align-items: center;
    }

    #social-media {
        justify-content: center;
    }

    .white-break {
        width: 300px;
    }

}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
