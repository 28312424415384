.skill-container {
    background-color: rgb(94, 161, 236);
    border-radius: 10px;
    width: 200px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skill-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style-type: none;
}

#communication-skill {
    font-size: 1.3em;
}
