.details-container {
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    width: 97%;
    height: 97%;
    padding: 2.5%;
    opacity: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    overflow: scroll;
    z-index: 2;
}

#x-close {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 3%;
    right: 3%;
    cursor: pointer;
}

#details-container-header {
    font-size: 40px;
}

#details-container-summary {
    width: 80vw;
    font-size: 25px;
}

.demo-gif {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}

.demo-gif label {
    text-align: center;
    margin-top: 10px;
}
