.project-container {
    border-radius: 10px;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blueviolet;
}

.project-tile {
    border-radius: 10px;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-details {
    position: absolute;
}

#ForYouPage {
    background-image: url("../../images/tiles/ForYouPage-Tile.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#Mockup {
    background-image: url("../../images/tiles/mockup-tile.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#SpotifyHangman {
    background-image: url("../../images/tiles/SpotifyHangman-Tile.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#AuthenticateMe {
    background-image: url("../../images/tiles/AuthenticateMe-Tile.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

div.project-tile span {
    color: rgba(0, 0, 0, 0.762);
    font-size: 50px;
}
